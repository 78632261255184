type Type = {
  [e: string]: string;
};

export const breadcrumbs: Type = {
  accreditation: 'Аккредитация',
  'fz-223': '223 Ф3',
  commercial: 'Коммерческие закупки',
  'e-shop': 'Электронный магазин',
  prequalification: 'Предквалификация',
  'construction-materials': 'Строительные материалы',
  'construction-services': 'Строительно-монтажные работы',
  food: 'Продукты питания',
  logistics: 'Логистика',
  medicine: 'Медицина',
};
