import React from 'react';

import { IconsProps } from './Icons.type.js';

export const MskIcon: React.FC<IconsProps> = ({
  fill = '#BDCCD4',
  timeZoneCode = 'мск',
}) => {
  return (
    <svg
      width="61"
      height="24"
      viewBox="0 0 61 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        // eslint-disable-next-line max-len
        d="M20.4853 3.51473C18.2188 1.24823 15.2054 0 12 0C8.79469 0 5.78123 1.24819 3.51473 3.51473C1.24823 5.78123 0 8.79469 0 12C0 15.2053 1.24823 18.2188 3.51473 20.4853C5.78123 22.7518 8.79469 24 12 24C15.2054 24 18.2188 22.7518 20.4853 20.4853C22.7518 18.2188 24 15.2053 24 12C24 8.79469 22.7518 5.78123 20.4853 3.51473ZM8.41575 2.03091C8.04169 2.50823 7.69341 3.06473 7.37705 3.69745C6.34636 5.75887 5.74425 8.4352 5.65927 11.2969H1.43016C1.71187 7.0193 4.5435 3.42736 8.41575 2.03091ZM1.43016 12.7031H5.65931C5.74425 15.5647 6.34636 18.2411 7.37709 20.3025C7.69345 20.9353 8.04178 21.4918 8.41584 21.9691C4.5435 20.5726 1.71187 16.9807 1.43016 12.7031ZM11.2969 22.4759C10.3253 22.1517 9.38297 21.1699 8.63484 19.6736C7.69833 17.8007 7.14895 15.3472 7.06603 12.7031H11.2969V22.4759ZM11.2969 11.2969H7.06608C7.149 8.65275 7.69838 6.19927 8.63484 4.32633C9.38297 2.83012 10.3253 1.84833 11.2969 1.52409V11.2969ZM12.7031 22.5698V13.6975L13.633 14.6273C13.7703 14.7646 13.9502 14.8333 14.1301 14.8333C14.3101 14.8333 14.49 14.7647 14.6273 14.6273C14.9019 14.3527 14.9019 13.9076 14.6273 13.633L12.9944 12L14.6273 10.367C14.9019 10.0924 14.9019 9.64725 14.6273 9.37266C14.3528 9.09806 13.9076 9.09806 13.633 9.37266L12.7031 10.3025V1.43016C18.2179 1.79334 22.5938 6.39492 22.5938 12C22.5938 17.6051 18.2179 22.2067 12.7031 22.5698Z"
        fill={fill}
      />
      <text fill={fill} x={36} y={14} fontSize="small">
        {timeZoneCode}
      </text>
    </svg>
  );
};
