import React from 'react';
import { IconsProps } from './Icons.type.js';

export const MonitorIcon: React.FC<IconsProps> = (props) => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M43.7812 10.0078H34.0822L33.8603 6.77784H29.5251V5.5485C29.5251 2.502 27.0465 0.0234375 24 0.0234375C20.9535 0.0234375 18.4749 2.502 18.4749 5.5485V6.77784H14.1396L13.9177 10.0078H4.21875C1.89253 10.0078 0 11.9003 0 14.2266V37.1953C0 39.5215 1.89253 41.4141 4.21875 41.4141H17.9359V43.7578C17.9359 44.5332 17.305 45.1641 16.5296 45.1641H12.75V47.9766H35.25V45.1641H31.4704C30.695 45.1641 30.0641 44.5332 30.0641 43.7578V41.4141H43.7812C46.1075 41.4141 48 39.5215 48 37.1953V14.2266C48 11.9003 46.1075 10.0078 43.7812 10.0078ZM21.2874 5.5485C21.2874 4.05281 22.5042 2.83594 24 2.83594C25.4958 2.83594 26.7126 4.05272 26.7126 5.5485V6.77784H21.2875V5.5485H21.2874ZM16.7656 9.59034H31.2344L32.3899 26.4141H15.6101L16.7656 9.59034ZM4.21875 12.8203H13.7246L12.5979 29.2266H35.4022L34.2754 12.8203H43.7812C44.5567 12.8203 45.1875 13.4512 45.1875 14.2266V32.9766H2.8125V14.2266C2.8125 13.4512 3.44334 12.8203 4.21875 12.8203ZM27.4935 45.1641H20.5065C20.6626 44.7239 20.7484 44.2508 20.7484 43.7578V41.4141H27.2515V43.7578C27.2516 44.2508 27.3374 44.7239 27.4935 45.1641ZM43.7812 38.6016H4.21875C3.44334 38.6016 2.8125 37.9707 2.8125 37.1953V35.7891H45.1875V37.1953C45.1875 37.9707 44.5567 38.6016 43.7812 38.6016Z"
      fill="white"
    />
    <path
      d="M24.0005 13.7458C21.6535 13.7458 19.7441 15.6553 19.7441 18.0022C19.7441 20.3491 21.6535 22.2585 24.0005 22.2585C26.3474 22.2585 28.2567 20.3491 28.2567 18.0022C28.2567 15.6553 26.3474 13.7458 24.0005 13.7458ZM24.0005 19.4459C23.2044 19.4459 22.5566 18.7982 22.5566 18.0021C22.5566 17.206 23.2044 16.5583 24.0005 16.5583C24.7966 16.5583 25.4442 17.206 25.4442 18.0021C25.4442 18.7982 24.7966 19.4459 24.0005 19.4459Z"
      fill="white"
    />
  </svg>
);
