import { ContactsProps } from '../Info/Contacts/Contacts.type.js';

interface IContactsDummy {
  support: ContactsProps['supportContacts'];
  additional: ContactsProps['additionalContacts'];
  address: ContactsProps['officeAddress'];
  manager: ContactsProps['generalManager'];
}

export const contactsDummy: IContactsDummy = {
  support: [
    {
      id: 1,
      type: 'phone',
      contact: '+7 (800) 555-20-83',
    },
    {
      id: 2,
      type: 'phone',
      contact: '+7 (495) 419-06-01',
    },
    {
      id: 3,
      type: 'phone',
      contact: '+7 (495) 215-22-02',
    },
    {
      id: 4,
      type: 'email',
      contact: 'support@praktis.ru',
    },
  ],
  additional: [
    {
      id: 1,
      type: 'phone',
      contact: '+7 (495) 212-14-55',
    },
    {
      id: 2,
      type: 'email',
      contact: 'info@praktis.ru',
    },
  ],
  address: '191187, г. Санкт-Петербург, ул. Шпалерная, д.2/4',
  manager: 'Константинов Илья Ильич',
};
