import { EstpOffer } from '../types/entities.js';
import { cachedFetchDocuments } from '../api/fetchDocuments.js';
import { noop } from 'lodash';
import { ReactNode, useEffect } from 'react';
import { useState } from 'react';
import { useMemo } from 'react';
import { useContext } from 'react';
import { FC } from 'react';
import React from 'react';
import { createContext } from 'react';

interface LayoutContextType {
  embedded: boolean;
  setEmbedded: (value: boolean) => void;
  offerLinks: OfferLink[];
}

type OfferLink = {
  title: string;
  link: string;
};

export const LayoutContext = createContext<LayoutContextType>({
  embedded: false,
  setEmbedded: noop,
  offerLinks: [],
});

export interface LayoutProviderProps {
  initialValues: Pick<LayoutContextType, 'embedded'>;
  children?: ReactNode;
}

export const LayoutProvider: FC<LayoutProviderProps> = ({
  initialValues,
  children,
}) => {
  const [embedded, setEmbedded] = useState<boolean>(initialValues.embedded);
  const [offerLinks, setOfferLinks] = useState<OfferLink[]>([]);

  useEffect(() => {
    if (!offerLinks.length) {
      (async () => {
        try {
          const request = await fetch('/api/fetchOfferLinks', {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            },
          });
          const { success, data: links } = await request.json();

          if (success && links.length) {
            setOfferLinks(links);
          }
        } catch (error) {
          // console.log('error', error);
        }
      })();
    }
  }, [offerLinks]);

  const value = useMemo(
    () => ({
      embedded,
      setEmbedded,
      offerLinks,
    }),
    [embedded, offerLinks],
  );

  return (
    <LayoutContext.Provider value={value}>{children}</LayoutContext.Provider>
  );
};

export const useLayoutContext = () => useContext(LayoutContext);
