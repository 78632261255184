import React from 'react';
import { IconsProps } from './Icons.type.js';
import { SvgIcon } from '../../base-components/SvgIcon.js';

export const RigthArrowIcon: React.FC<IconsProps> = ({
  className,
  fill = '#ED1C24',
}) => {
  return (
    <SvgIcon width={16} height={17} className={className} noFill noStroke>
      <g id="flash">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.6869 8.14661C13.7805 8.24036 13.8331 8.36744 13.8331 8.49994C13.8331 8.63244 13.7805 8.75953 13.6869 8.85328L9.6869 12.8533C9.64113 12.9024 9.58593 12.9418 9.5246 12.9691C9.46326 12.9965 9.39705 13.0112 9.32992 13.0123C9.26278 13.0135 9.1961 13.0012 9.13384 12.976C9.07158 12.9509 9.01502 12.9134 8.96755 12.866C8.92007 12.8185 8.88264 12.7619 8.85749 12.6997C8.83234 12.6374 8.81999 12.5707 8.82118 12.5036C8.82236 12.4365 8.83706 12.3703 8.86438 12.3089C8.89171 12.2476 8.93111 12.1924 8.98024 12.1466L12.1269 8.99994H2.6669C2.5343 8.99994 2.40712 8.94727 2.31335 8.8535C2.21958 8.75973 2.1669 8.63255 2.1669 8.49994C2.1669 8.36734 2.21958 8.24016 2.31335 8.14639C2.40712 8.05262 2.5343 7.99994 2.6669 7.99994H12.1269L8.98024 4.85328C8.93111 4.8075 8.89171 4.7523 8.86438 4.69097C8.83706 4.62964 8.82236 4.56343 8.82118 4.49629C8.81999 4.42916 8.83234 4.36247 8.85749 4.30021C8.88264 4.23795 8.92007 4.1814 8.96755 4.13392C9.01502 4.08644 9.07158 4.04901 9.13384 4.02386C9.1961 3.99872 9.26278 3.98637 9.32992 3.98755C9.39705 3.98873 9.46326 4.00343 9.5246 4.03076C9.58593 4.05809 9.64113 4.09749 9.6869 4.14661L13.6869 8.14661Z"
          fill={fill}
        />
      </g>
    </SvgIcon>
  );
};
