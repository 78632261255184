const ETP_HOSTNAME = process.env.ETP_HOSTNAME;

const getOrigin = () => {
  if (typeof window !== 'undefined') {
    return window.location.origin;
  }
  if (ETP_HOSTNAME) {
    return `https://${ETP_HOSTNAME}`;
  }
  throw new Error("Can't detect site origin");
};

export const getHref = (url: string = '') => {
  return new URL(url, getOrigin()).href;
};
