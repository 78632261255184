import React, { FunctionComponent } from 'react';
import { IconsProps } from './Icons.type.js';

export const ElementIcon: FunctionComponent<IconsProps> = (props) => (
  <svg
    width="64"
    height="64"
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M52.72 30H41.9467C36.5867 30 34 27.3867 34 22.0534V11.28C34 5.92004 36.6133 3.33337 41.9467 3.33337H52.72C58.08 3.33337 60.6667 5.94671 60.6667 11.28V22.0534C60.6667 27.3867 58.0533 30 52.72 30ZM41.9467 7.33337C38.8 7.33337 38 8.13337 38 11.28V22.0534C38 25.2 38.8 26 41.9467 26H52.72C55.8667 26 56.6667 25.2 56.6667 22.0534V11.28C56.6667 8.13337 55.8667 7.33337 52.72 7.33337H41.9467Z"
      fill="#333333"
    />
    <path
      d="M22.054 30H11.2807C5.92065 30 3.33398 27.6267 3.33398 22.72V10.6134C3.33398 5.70671 5.94732 3.33337 11.2807 3.33337H22.054C27.414 3.33337 30.0006 5.70671 30.0006 10.6134V22.6934C30.0006 27.6267 27.3873 30 22.054 30ZM11.2807 7.33337C7.70732 7.33337 7.33398 8.34671 7.33398 10.6134V22.6934C7.33398 24.9867 7.70732 25.9734 11.2807 25.9734H22.054C25.6273 25.9734 26.0007 24.96 26.0007 22.6934V10.6134C26.0007 8.32004 25.6273 7.33337 22.054 7.33337H11.2807Z"
      fill="#333333"
    />
    <path
      d="M22.054 60.6667H11.2807C5.92065 60.6667 3.33398 58.0533 3.33398 52.72V41.9467C3.33398 36.5867 5.94732 34 11.2807 34H22.054C27.414 34 30.0006 36.6133 30.0006 41.9467V52.72C30.0006 58.0533 27.3873 60.6667 22.054 60.6667ZM11.2807 38C8.13398 38 7.33398 38.8 7.33398 41.9467V52.72C7.33398 55.8667 8.13398 56.6667 11.2807 56.6667H22.054C25.2007 56.6667 26.0007 55.8667 26.0007 52.72V41.9467C26.0007 38.8 25.2007 38 22.054 38H11.2807Z"
      fill="#333333"
    />
    <path
      d="M56 43.3334H40C38.9067 43.3334 38 42.4267 38 41.3334C38 40.24 38.9067 39.3334 40 39.3334H56C57.0933 39.3334 58 40.24 58 41.3334C58 42.4267 57.0933 43.3334 56 43.3334Z"
      fill="#333333"
    />
    <path
      d="M56 54H40C38.9067 54 38 53.0933 38 52C38 50.9067 38.9067 50 40 50H56C57.0933 50 58 50.9067 58 52C58 53.0933 57.0933 54 56 54Z"
      fill="#333333"
    />
  </svg>
);
