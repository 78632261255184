import { Link } from '@/npm/next.js';
import React from 'react';
import { LogoIconDark } from '../Icons/index.js';
import { rootLinks } from '../Links.js';
import { Col } from '../Purchases/components/Col.js';
import { Row } from '../Purchases/components/Row.js';
import styles from './Footer.module.scss';

export const Footer: React.FC = () => {
  return (
    <footer>
      <div className={styles.container}>
        <Row className={styles.firstRow}>
          <LogoIconDark />
          <ul className={styles.rowMenu}>
            <li>
              <Link href={`${rootLinks.main.url}#eko`}>Участникам</Link>
            </li>
            <li>
              <Link href={rootLinks.purchases.url}>Закупки</Link>
            </li>
            <li>
              <Link href={`${rootLinks.main.url}#tariffs`}>Тарифы</Link>
            </li>
            <li>
              <Link href={`${rootLinks.main.url}#eko`}>О компании</Link>
            </li>
            <li>
              <Link href={`${rootLinks.main.url}#eko`}>Экосистема Praktis</Link>
            </li>
          </ul>
          <Col className={styles.col}>
            <p className={styles.phone}>
              <a href="tel:88123354489">
                <span>+7 812 335-44-89</span>
              </a>
            </p>
            <p className={styles.email}>
              <a
                rel="noopener noreferrer"
                target="_blank"
                href="mailto:info@praktis.ru"
              >
                info@praktis.ru
              </a>
            </p>
            {/* <p className={styles.address}>
              Россия, Санкт-Петербург, Ушаковская набережная, д.3 корп.1 /
              Московский пр. 212
            </p> */}
          </Col>
        </Row>
      </div>
    </footer>
  );
};
