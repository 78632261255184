import range from 'lodash/range.js';
import { AboutProps } from '../Info/About/About.type.js';
import setId from './utils/setId.js';

const images = range(0, 12).map(
  (index) => `/assets/dummy-data/licenses/${index + 1}.png`,
);

export const licensesDummy: AboutProps['licenses'] = [
  {
    image: images[0],
    downloadUrl: '/assets/licenses/evm.pdf',
    text: 'Свидетельство о государственной регистрации программы для ЭВМ',
  },
  {
    image: images[1],
    downloadUrl: '/assets/licenses/smi.pdf',
    text: 'Свидетельство о регистрации средства массовой информации',
  },
  {
    image: images[2],
    downloadUrl: '/assets/licenses/vipiska-pd.pdf',
    text: 'Выписка из реестра операторов, осуществляющих обработку персональных данных',
  },
  {
    image: images[3],
    downloadUrl: '/assets/licenses/licenziya-fsb.pdf',
    text: 'Лицензия ФСБ России',
  },
  {
    image: images[4],
    downloadUrl: '/assets/licenses/licenziya-fstek-razrabotka.pdf',
    text: 'Лицензия на деятельность по разработке и производству средств защиты конфиденциальной информации',
  },
  {
    image: images[5],
    downloadUrl: '/assets/licenses/licenziya-fstek.pdf',
    text: 'Лицензия на деятельность по технической защите конфиденциальной информации',
  },
  {
    image: images[6],
    downloadUrl: '/assets/licenses/tovarniy-znak.pdf',
    text: 'Свидетельство на товарный знак (знак обслуживания) № 541885',
  },
  {
    image: images[9],
    downloadUrl: '/assets/licenses/telematika.pdf',
    text: 'Лицензия на оказание телематических услуг связи',
  },
  {
    image: images[10],
    downloadUrl: '/assets/licenses/cert-fstek.pdf',
    text: 'Сертификат соответствия требованиям информационной безопасности № 3551',
  },
].map(setId);
