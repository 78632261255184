import { Head } from '@/npm/next.js';
import { FC } from 'react';
import { metaTags } from './_dummyData/index.js';
interface Props {
  title?: string;
  description?: string;
  url?: string;
  keywords?: string;
  noindex?: boolean;
}

const Meta: FC<Props> = ({ title, description, url, keywords, noindex }) => {
  return (
    <Head>
      <title>{title}</title>
      {noindex && <meta name="robots" content="noindex, nofollow" />}
      <meta name="description" content={description} key="description" />
      <meta name="keywords" content={keywords} key="keywords" />
      <meta name="yandex-verification" content="c4a5ec5979af73cd" />
      <meta property="og:title" content={title} key="title" />
      <meta property="og:url" content={url} key="url" />
      <meta
        property="og:description"
        content={description}
        key="ogDescription"
      />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
    </Head>
  );
};

Meta.defaultProps = {
  title: metaTags.main.title,
  description: metaTags.main.description,
  url: metaTags.main.url,
  keywords: metaTags.main.keywords,
};

export default Meta;
