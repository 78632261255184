import clsx from 'clsx';
import { ReactNode } from 'react';
import React from 'react';
import styles from './SvgIcon.module.scss';

interface Props {
  size?: number;
  width?: number;
  height?: number;
  noFill?: boolean;
  noStroke?: boolean;
  className?: string;
  children?: ReactNode;
}

export const SvgIcon: React.FC<Props> = ({
  size,
  width,
  height,
  children,
  noFill,
  noStroke,
  className,
}) => (
  <svg
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    height={height || size || 0}
    width={width || size || 0}
    aria-hidden="true"
    focusable="false"
    className={clsx(
      styles.icon,
      !noFill && styles.fill,
      !noStroke && styles.stroke,
      className,
    )}
  >
    {children}
  </svg>
);
