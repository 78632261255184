import clsx from 'clsx';
import { Link } from '@/npm/next.js';
import React, { type ReactNode, useCallback, useMemo } from 'react';
import { LoggedInUser } from '../../types/entities.js';
import { LogoIconLight, ThreeLinesIcon } from '../Icons/index.js';
import { rootLinks } from '../Links.js';
import styles from './Header.module.scss';
import { getHref } from '../../utils/getHref.js';
import { usePathname } from 'next/navigation.js';
import { Button } from '@/base-components/Button.js';
import { useRouter } from 'next/router.js';

const MenuLink: React.FC<{
  href: string;
  children: ReactNode;
  className?: string;
}> = ({ href, children, className }) => {
  const pathname = usePathname();
  const isLinkActive = useMemo(() => {
    return pathname.startsWith(href);
  }, [href, pathname]);
  return (
    <Link
      href={href}
      className={clsx(className, isLinkActive && styles.active)}
    >
      {children}
    </Link>
  );
};
interface Props {
  loggedInUser: LoggedInUser | null;
  logout: () => Promise<void>;
}

export const Header: React.FC<Props> = ({ loggedInUser, logout }) => {
  const router = useRouter();
  const onEnter = useCallback(() => {
    router.push(loggedInUser ? getHref('/personal') : rootLinks.auth.url);
  }, [loggedInUser, router]);

  return (
    <header className={styles.wrapper}>
      <div className={styles.container}>
        <MenuLink href={rootLinks.main.url} className={styles.logo}>
          <LogoIconLight />
        </MenuLink>
        <ul className={styles.menu}>
          <li className={styles.menuItem}>
            <MenuLink
              href={`${rootLinks.main.url}#eko`}
              className={styles.menuLink}
            >
              Участникам
            </MenuLink>
          </li>
          <li className={styles.menuItem}>
            <MenuLink
              href={rootLinks.purchases.url}
              className={styles.menuLink}
            >
              Закупки
            </MenuLink>
          </li>
          <li className={styles.menuItem}>
            <MenuLink
              href={`${rootLinks.main.url}#tariffs`}
              className={styles.menuLink}
            >
              Тарифы
            </MenuLink>
          </li>
          <li className={styles.menuItem}>
            <MenuLink
              href={`${rootLinks.main.url}#eko`}
              className={styles.menuLink}
            >
              О компании
            </MenuLink>
          </li>
          <li className={styles.menuItem}>
            <MenuLink
              href={`${rootLinks.main.url}#eko`}
              className={styles.menuLink}
            >
              Экосистема Praktis
            </MenuLink>
          </li>
          {/* <li className={styles.menuItem}>
            <MenuLink href={rootLinks.info.url} className={styles.menuLink}>
              О площадке
            </MenuLink>
          </li> */}
        </ul>

        <div className={styles.phone}>
          <a href="tel:88123354489">
            <span>8 812 335 44 89</span>
          </a>
        </div>

        <Button
          onClick={onEnter}
          variant="blue"
          size="large"
          className={styles.login}
        >
          Войти
        </Button>

        <div className={styles.lines} onClick={onEnter}>
          <ThreeLinesIcon fill="#ffffff" />
        </div>
      </div>
    </header>
  );
};
