import { SvgIcon } from '../../base-components/SvgIcon.js';

export const CertListArrowDown = ({ className }: { className: string }) => {
  return (
    <SvgIcon width={12} height={8} noFill noStroke className={className}>
      <path
        d="m1 1.5 5 5 5-5"
        stroke="#2D9CDB"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};
