import { FC } from 'react';
import React from 'react';
import { SvgIcon } from '../../base-components/SvgIcon.js';
import { IconsProps } from './Icons.type.js';

/** Иконка с прямоугольной стрелочкой вправо-вверх */
export const ArrowUpRightIcon: FC<IconsProps> = ({ fill = 'white' }) => (
  <SvgIcon width={12} height={12} noFill noStroke>
    <path
      d="M11.9241 0.617216C11.8757 0.500141 11.804 0.3904 11.7092 0.295017C11.7078 0.293599 11.7064 0.292185 11.705 0.290776C11.5242 0.111056 11.2751 0 11 0H1C0.447715 0 4.47035e-08 0.447715 4.47035e-08 1C4.47035e-08 1.55228 0.447715 2 1 2H8.58579L0.292893 10.2929C-0.0976311 10.6834 -0.0976311 11.3166 0.292893 11.7071C0.683417 12.0976 1.31658 12.0976 1.70711 11.7071L10 3.41421V11C10 11.5523 10.4477 12 11 12C11.5523 12 12 11.5523 12 11V1.00069C12 0.999693 12 0.998 12 0.997001C11.9996 0.862504 11.9727 0.734253 11.9241 0.617216Z"
      fill={fill}
    />
  </SvgIcon>
);
