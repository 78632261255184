import React, { FunctionComponent } from 'react';
import { IconsProps } from './Icons.type.js';

export const WalletIcon: FunctionComponent<IconsProps> = (props) => (
  <svg
    width="64"
    height="64"
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M47.306 60.6666H16.6927C10.0527 60.6666 4.66602 55.2799 4.66602 48.6399V30.6933C4.66602 24.0533 10.0527 18.6666 16.6927 18.6666H47.306C53.946 18.6666 59.3327 24.0533 59.3327 30.6933V34.5333C59.3327 35.6267 58.426 36.5333 57.3327 36.5333H51.946C51.0127 36.5333 50.1593 36.88 49.546 37.52L49.5193 37.5467C48.7727 38.2667 48.426 39.2532 48.506 40.2666C48.666 42.0266 50.346 43.4399 52.266 43.4399H57.3327C58.426 43.4399 59.3327 44.3466 59.3327 45.4399V48.6132C59.3327 55.2799 53.946 60.6666 47.306 60.6666ZM16.6927 22.6666C12.266 22.6666 8.66602 26.2667 8.66602 30.6933V48.6399C8.66602 53.0666 12.266 56.6666 16.6927 56.6666H47.306C51.7327 56.6666 55.3327 53.0666 55.3327 48.6399V47.4666H52.266C48.2393 47.4666 44.826 44.4799 44.506 40.6399C44.2927 38.4533 45.0927 36.2933 46.6927 34.72C48.0793 33.3067 49.946 32.5333 51.946 32.5333H55.3327V30.6933C55.3327 26.2667 51.7327 22.6666 47.306 22.6666H16.6927Z"
      fill="#333333"
    />
    <path
      d="M6.66602 35.0934C5.57268 35.0934 4.66602 34.1867 4.66602 33.0934V20.9069C4.66602 16.9335 7.17268 13.3334 10.8793 11.9201L32.0527 3.92005C34.2394 3.09339 36.666 3.38685 38.5593 4.72018C40.4794 6.05352 41.5993 8.2135 41.5993 10.5335V20.6668C41.5993 21.7601 40.6927 22.6668 39.5993 22.6668C38.506 22.6668 37.5993 21.7601 37.5993 20.6668V10.5335C37.5993 9.52016 37.1194 8.5868 36.266 8.00013C35.4127 7.41347 34.3993 7.28012 33.4394 7.65345L12.266 15.6535C10.106 16.4801 8.63935 18.5869 8.63935 20.9069V33.0934C8.66602 34.2134 7.75935 35.0934 6.66602 35.0934Z"
      fill="#333333"
    />
    <path
      d="M52.2666 47.4665C48.2399 47.4665 44.8266 44.4798 44.5066 40.6398C44.2933 38.4265 45.0933 36.2665 46.6933 34.6932C48.0533 33.3065 49.9199 32.5332 51.9199 32.5332H57.4666C60.1066 32.6132 62.1333 34.6931 62.1333 37.2531V42.7466C62.1333 45.3066 60.1066 47.3865 57.5466 47.4665H52.2666ZM57.4133 36.5332H51.9466C51.0133 36.5332 50.1599 36.8799 49.5466 37.5199C48.7733 38.2665 48.3999 39.2798 48.5066 40.2931C48.6666 42.0531 50.3466 43.4665 52.2666 43.4665H57.4933C57.8399 43.4665 58.1599 43.1466 58.1599 42.7466V37.2531C58.1599 36.8531 57.8399 36.5599 57.4133 36.5332Z"
      fill="#333333"
    />
    <path
      d="M37.3327 34H18.666C17.5727 34 16.666 33.0933 16.666 32C16.666 30.9067 17.5727 30 18.666 30H37.3327C38.426 30 39.3327 30.9067 39.3327 32C39.3327 33.0933 38.426 34 37.3327 34Z"
      fill="#333333"
    />
  </svg>
);
