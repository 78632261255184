/* eslint-disable max-len */
interface IMemberDummy {
  benefits: {
    title: string;
    description: string;
  }[];
}

export const membersDummy: IMemberDummy = {
  benefits: [
    {
      title: 'Гарантия занятости',
      description: 'Объём застройки и возможности компании позволяют гарантировать работу на постоянной основе',
    },
    {
      title: 'Квалификация сотрудников',
      description: 'Работа в команде профессионалов',
    },
    {
      title: 'Долгосрочное сотрудничество',
      description: 'Setl Group - застройщик №1 в Санкт-Петербурге по объему текущего строительства',
    },
    {
      title: 'Индивидуальный подход',
      description: 'При работе с партнерами выбираем лучшие условия финансирования',
    },
    {
      title: 'Необходимые ресурсы',
      description: 'Застройщик предоставляет все необходимые материалы для строительства',
    },
  ],
};
