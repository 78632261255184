/* eslint-disable max-len */
import React from 'react';
import { IconsProps } from './Icons.type.js';

export const GiftIcon: React.FC<IconsProps> = ({ fill = 'white' }) => (
  <svg
    width="58"
    height="59"
    viewBox="0 0 58 59"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M50.4 13.7H7.5C6.67157 13.7 6 14.3716 6 15.2V24.95C6 25.7784 6.67157 26.45 7.5 26.45H17.475H40.425H50.4C51.2284 26.45 51.9 25.7784 51.9 24.95V15.2C51.9 14.3716 51.2284 13.7 50.4 13.7Z"
      fill="#ED1C24"
    />
    <path
      d="M28.9502 6.5C28.9502 4.84314 30.2933 3.5 31.9502 3.5H38.7002C40.357 3.5 41.7002 4.84315 41.7002 6.5V10.7C41.7002 12.3569 40.357 13.7 38.7002 13.7H28.9502V6.5Z"
      fill="#ED1C24"
      stroke="white"
      strokeWidth="1.5"
    />
    <path
      d="M28.9502 6.5C28.9502 4.84314 27.607 3.5 25.9502 3.5H19.2002C17.5433 3.5 16.2002 4.84315 16.2002 6.5V10.7C16.2002 12.3569 17.5433 13.7 19.2002 13.7H28.9502V6.5Z"
      fill="#ED1C24"
      stroke="white"
      strokeWidth="1.5"
    />
    <path
      opacity="0.3"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.5498 30.275V50.5C8.5498 52.7091 10.3407 54.5 12.5498 54.5H26.9103V30.275H8.5498ZM30.7353 30.275L30.7353 54.5H45.3498C47.5589 54.5 49.3498 52.7091 49.3498 50.5V30.275H30.7353Z"
      fill="#ED1C24"
    />
  </svg>
);
