import find from 'lodash/find.js';
import { Link } from '@/npm/next.js';
import { useMemo } from 'react';
import React from 'react';
import { OrgSection } from '../../../types/entities.js';
import { getHref } from '../../../utils/getHref.js';
import { rootLinks } from '../../Links.js';
import styles from './Company.module.scss';

const getSectionHref = (sectionOrg: string) => ({
  pathname: rootLinks.purchases.url,
  query: { sectionOrg },
});

export type CompanyProps = {
  orgSection: OrgSection;
};

export const Company: React.FC<CompanyProps> = ({ orgSection }) => {
  const logo = useMemo(
    () => find(orgSection?.organization?.publicProps, ['type', 'logo']) || null,
    [orgSection?.organization?.publicProps],
  );
  const shortName = orgSection?.organization?.ext?.shortName || '';

  if (!logo || !shortName) {
    return null;
  }

  return (
    <Link href={getSectionHref(orgSection.name)} className={styles.container}>
      <div className={styles.logo}>
        <img
          src={getHref(logo.edeFile?.url)}
          alt={`Логотип компании ${shortName}`}
          className={styles.logoImage}
        />
      </div>
      <div className={styles.delimiter} />
      <div className={styles.title}>{shortName}</div>
    </Link>
  );
};
