import { noop } from 'lodash';
import { ReactNode } from 'react';
import { useEffect } from 'react';
import { useMemo } from 'react';
import { useContext } from 'react';
import { FC } from 'react';
import React from 'react';
import { createContext } from 'react';
import { useAsyncFn } from 'react-use';
import useSWR from 'swr';
import { Session } from '../types/entities.js';
import { LoggedInUser } from '../types/entities.js';

const fetcher = <T,>(url: string, params?: RequestInit) =>
  fetch(url, params).then((res) => res.json() as Promise<T>);

interface UserContextType {
  user: LoggedInUser | null;
  login: (formData: any) => Promise<void>;
  loginState: {
    loading: boolean;
    error?: { message: string } | null;
  };
  logout: () => Promise<void>;
  logoutState: {
    loading: boolean;
    error?: { message: string } | null;
  };
}

export const UserContext = createContext<UserContextType>({
  user: null,
  login: () => Promise.resolve(),
  loginState: {
    loading: false,
  },
  logout: () => Promise.resolve(),
  logoutState: {
    loading: false,
  },
});

export const UserProvider = ({ children }: { children?: ReactNode }) => {
  const { data: session, mutate: mutateSession } = useSWR<Session | null>(
    '/api/auth/user',
    fetcher,
  );

  const user: LoggedInUser | null = useMemo(() => {
    if (session?.isLoggedIn) {
      return session.user;
    }
    return null;
  }, [session]);

  const [logoutState, logout] = useAsyncFn(async () => {
    const { isLoggedIn } = await fetcher<Session>('/api/auth/logout', {
      method: 'POST',
    });
    if (!isLoggedIn) {
      mutateSession(null, false);
    }
  }, []);

  const [loginState, login] = useAsyncFn(async (formData: any) => {
    const loginResponse = await fetcher<Session>('/api/auth/login', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(formData),
    });
    if (loginResponse.isLoggedIn) {
      mutateSession(loginResponse);
    } else {
      const { error } = loginResponse;
      if (error) {
        throw error;
      } else {
        throw new Error('Неизвестная ошибка');
      }
    }
  });

  return (
    <UserContext.Provider
      value={{
        user,
        login: (value: any) => login(value).catch(noop),
        loginState,
        logout: () => logout().catch(noop),
        logoutState,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUserContext = () => useContext(UserContext);
