import setId from './utils/setId.js';

export const mainStatsDummy = [
  {
    count: 17,
    symbol: '%',
    text: 'Средняя экономия на торгах',
  },
  {
    count: 87,
    symbol: '%',
    text: 'Доля процедур, завершившихся выбором победителя',
  },
  {
    count: 4,
    text: 'Среднее число участников в закупке',
  },
].map(setId);
