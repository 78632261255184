import { ReactPaginate } from '@/npm/paginate.js';
import React, { FunctionComponent } from 'react';

import { ArrowRightIcon } from '../../Icons/index.js';

import styles from './Pagination.module.scss';
import { PaginationProps } from './Pagination.type.js';

export const Pagination: FunctionComponent<PaginationProps> = ({
  totalPages,
  initialPage,
  handleChangePage,
}) => (
  <ReactPaginate
    previousLabel={<ArrowRightIcon className={styles.leftIcon} />}
    nextLabel={<ArrowRightIcon className={styles.rightIcon} />}
    breakLabel="..."
    breakClassName={styles.paginationBreak}
    pageCount={totalPages}
    marginPagesDisplayed={1}
    pageRangeDisplayed={4}
    onPageChange={(selectedItem: any) =>
      handleChangePage(selectedItem.selected)
    }
    containerClassName={styles.pagination}
    activeClassName={styles.paginationActive}
    initialPage={initialPage}
  />
);
