import React, { FunctionComponent } from 'react';
import { IconsProps } from './Icons.type.js';

export const CircleIcon: FunctionComponent<IconsProps> = (props) => (
  <svg
    width="64"
    height="64"
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M21.3325 39.3333C20.8259 39.3333 20.3192 39.1467 19.9192 38.7467L14.5859 33.4134C13.8125 32.64 13.8125 31.3599 14.5859 30.5866L19.9192 25.2532C20.6925 24.4799 21.9725 24.4799 22.7459 25.2532C23.5192 26.0266 23.5192 27.3067 22.7459 28.08L18.8258 32L22.7459 35.9199C23.5192 36.6932 23.5192 37.9734 22.7459 38.7467C22.3459 39.1467 21.8392 39.3333 21.3325 39.3333Z"
      fill="#333333"
    />
    <path
      d="M42.6672 39.3333C42.1605 39.3333 41.6538 39.1467 41.2538 38.7467C40.4805 37.9734 40.4805 36.6932 41.2538 35.9199L45.1738 32L41.2538 28.08C40.4805 27.3067 40.4805 26.0266 41.2538 25.2532C42.0272 24.4799 43.3072 24.4799 44.0805 25.2532L49.4138 30.5866C50.1872 31.3599 50.1872 32.64 49.4138 33.4134L44.0805 38.7467C43.6805 39.1467 43.1738 39.3333 42.6672 39.3333Z"
      fill="#333333"
    />
    <path
      d="M32.0007 60.6667C16.1873 60.6667 3.33398 47.8134 3.33398 32C3.33398 16.1867 16.1873 3.33337 32.0007 3.33337C47.814 3.33337 60.6673 16.1867 60.6673 32C60.6673 47.8134 47.814 60.6667 32.0007 60.6667ZM32.0007 7.33337C18.4007 7.33337 7.33398 18.4 7.33398 32C7.33398 45.6 18.4007 56.6667 32.0007 56.6667C45.6007 56.6667 56.6673 45.6 56.6673 32C56.6673 18.4 45.6007 7.33337 32.0007 7.33337Z"
      fill="#333333"
    />
    <path
      d="M29.3343 40.2135C29.0676 40.2135 28.801 40.1602 28.561 40.0535C27.5476 39.6268 27.0676 38.4535 27.521 37.4135L32.8543 24.9601C33.281 23.9467 34.4543 23.4667 35.4676 23.92C36.481 24.3467 36.961 25.5202 36.5076 26.5602L31.1743 39.0134C30.8543 39.7601 30.1076 40.2135 29.3343 40.2135Z"
      fill="#333333"
    />
  </svg>
);
