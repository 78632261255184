import React from 'react';
import { SvgIcon } from '../../base-components/SvgIcon.js';
import { IconsProps } from './Icons.type.js';

/** Иконка со страничкой */
export const PageIcon: React.FC<IconsProps> = ({
  className,
  fill = '#ED1C24',
}) => (
  <SvgIcon width={18} height={22} className={className} noFill noStroke>
    <g opacity="0.3">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.25 0.25634C11.1881 0.252128 11.1258 0.25 11.0633 0.25H5C2.37665 0.25 0.25 2.37665 0.25 5V17C0.25 19.6234 2.37665 21.75 5 21.75H13C15.6234 21.75 17.75 19.6234 17.75 17V7.75H14C12.4812 7.75 11.25 6.51878 11.25 5V0.25634ZM4.25 11C4.25 10.5858 4.58579 10.25 5 10.25H13C13.4142 10.25 13.75 10.5858 13.75 11C13.75 11.4142 13.4142 11.75 13 11.75H5C4.58579 11.75 4.25 11.4142 4.25 11ZM5 15.25C4.58579 15.25 4.25 15.5858 4.25 16C4.25 16.4142 4.58579 16.75 5 16.75H9C9.41421 16.75 9.75 16.4142 9.75 16C9.75 15.5858 9.41421 15.25 9 15.25H5Z"
        fill={fill}
      />
      <path
        d="M17.3215 6.25C17.2584 6.15059 17.1887 6.0549 17.1126 5.96359L13.1759 1.23949C13.048 1.08601 12.905 0.94837 12.75 0.828021V5C12.75 5.69036 13.3096 6.25 14 6.25H17.3215Z"
        fill={fill}
      />
    </g>
    <path d="M5 11H13" stroke={fill} strokeWidth="1.5" strokeLinecap="round" />
    <path d="M5 16H9" stroke={fill} stroke-width="1.5" stroke-linecap="round" />
  </SvgIcon>
);
