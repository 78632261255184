import React from 'react';
import { SvgIcon } from '../../base-components/SvgIcon.js';
import { IconsProps } from './Icons.type.js';

/** Иконка с красным навесным замком с белой галочкой в центре */
export const PadlockIcon: React.FC<IconsProps> = ({
  className,
  fill = '#ED1C24',
}) => (
  <SvgIcon width={18} height={20} className={className} noFill noStroke>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.25 11C0.25 8.37665 2.37665 6.25 5 6.25H13C15.6234 6.25 17.75 8.37665 17.75 11V15C17.75 17.6234 15.6234 19.75 13 19.75H5C2.37665 19.75 0.25 17.6234 0.25 15V11ZM6.46967 13.5303L7.96967 15.0303C8.26256 15.3232 8.73743 15.3232 9.03033 15.0303L12.0303 12.0303C12.3232 11.7374 12.3232 11.2626 12.0303 10.9697C11.7374 10.6768 11.2626 10.6768 10.9697 10.9697L8.5 13.4393L7.53033 12.4697C7.23744 12.1768 6.76256 12.1768 6.46967 12.4697C6.17678 12.7626 6.17678 13.2374 6.46967 13.5303Z"
      fill={fill}
    />
    <path
      opacity="0.3"
      d="M13 7V5C13 2.79086 11.2091 1 9 1V1C6.79086 1 5 2.79086 5 5L5 7"
      stroke={fill}
      strokeWidth="1.5"
    />
  </SvgIcon>
);
