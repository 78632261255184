import setId from './utils/setId.js';

export const mainProfitsDummy = [
  {
    price: 82045528489,
    company: 'строительство',
    currency: '₽',
  },
  {
    price: 12631468544,
    company: 'продукты питания',
    currency: '₽',
  },
  {
    price: 6034827095,
    company: 'медицина',
    currency: '₽',
  },
  {
    price: 2595473272,
    company: 'компьютеры и оргтехника',
    currency: '₽',
  },
  {
    price: 2163625468,
    company: 'сельское хозяйство',
    currency: '₽',
  },
  {
    price: 1097884500,
    company: 'логистика',
    currency: '₽',
  },
].map(setId);
