import React, { FunctionComponent } from 'react';
import { IconsProps } from './Icons.type.js';
import { SvgIcon } from '../../base-components/SvgIcon.js';

/** Иконка с логотипом площадки */
export const LogoIconDark: FunctionComponent<IconsProps> = ({
  className,
  fill = '#2E3241',
}) => (
  <SvgIcon width={96} height={58} className={className} noFill noStroke>
    <g clipPath="url(#clip0_71_762)">
      <path
        d="M40.7031 5.35245V14.6149H43.9031V8.33198L92.4794 4.31609V53.2195L43.9031 49.2036V39.2286H40.7031V52.1831L95.6794 56.7172V0.818359L40.7031 5.35245Z"
        fill="#168FFF"
      />
      <path
        d="M0.191406 18.6309H7.03942C9.02342 18.6309 10.5594 19.0843 11.7114 20.0559C12.8634 21.0275 13.3754 22.3877 13.3754 24.2013C13.3754 25.9502 12.7994 27.3104 11.7114 28.2172C10.6234 29.124 9.08742 29.6422 7.10342 29.6422H3.6474V36.3786H0.191406V18.6309ZM3.6474 26.7275H6.91142C7.80742 26.7275 8.57542 26.5331 9.08742 26.0797C9.59942 25.6263 9.85542 24.9786 9.85542 24.1365C9.85542 22.4525 8.83142 21.5456 6.84742 21.5456H3.71141V26.7275H3.6474Z"
        fill={fill}
      />
      <path
        d="M22.8507 23.6191H24.3867V26.6635H22.6587C21.6347 26.6635 20.8667 27.0521 20.3547 27.8294C19.8427 28.6066 19.5867 29.643 19.5867 31.068V36.3794H16.3867V23.6191H19.1387V26.3396C19.9067 24.526 21.1227 23.6191 22.8507 23.6191Z"
        fill={fill}
      />
      <path
        d="M31.6771 23.2949C33.4051 23.2949 34.7491 23.7483 35.7091 24.5904C36.6691 25.4324 37.1811 26.7927 37.1811 28.5415V36.379H34.4291V35.0188C33.5331 36.1847 32.2531 36.7029 30.5251 36.7029C29.1171 36.7029 27.9651 36.3142 27.1331 35.537C26.2371 34.7597 25.7891 33.7881 25.7891 32.6222C25.7891 31.3915 26.2371 30.4199 27.1331 29.7074C28.0291 28.9302 29.2451 28.5415 30.7171 28.5415C32.0611 28.5415 33.1491 28.8654 33.9811 29.5131V28.8006C33.9811 26.987 32.9571 26.0802 30.9731 26.0802C29.7571 26.0802 28.6051 26.404 27.4531 27.1165H27.1331V24.2017C28.6051 23.554 30.0771 23.2949 31.6771 23.2949ZM29.6931 33.6586C30.1411 33.9824 30.7811 34.112 31.6131 34.112C32.4451 34.112 33.0851 33.9824 33.5331 33.6586C33.9811 33.3347 34.2371 32.9461 34.2371 32.4279C34.2371 31.9097 33.9811 31.5211 33.5331 31.1972C33.0851 30.8733 32.4451 30.7438 31.6131 30.7438C30.7811 30.7438 30.1411 30.8733 29.6931 31.1972C29.2451 31.5211 28.9891 31.9097 28.9891 32.4279C28.9891 32.9461 29.2451 33.3347 29.6931 33.6586Z"
        fill={fill}
      />
      <path
        d="M52.5455 36.3785H48.5775L43.9695 30.2898V36.3785H40.7695V17.2705H43.9695V28.7353L48.1295 23.5535H52.0335L47.0415 29.3182L52.5455 36.3785Z"
        fill={fill}
      />
      <path
        d="M62.9086 26.2093H59.2606V31.5207C59.2606 32.1037 59.4526 32.5571 59.7726 32.8162C60.0926 33.14 60.5406 33.2696 61.1166 33.2696H62.7806V36.3139H60.5406C59.1326 36.3139 57.9806 35.9252 57.2126 35.2127C56.4446 34.5002 56.0606 33.4639 56.0606 32.1037V26.1446H53.3086V23.5537H56.0606V19.4082H59.2606V23.5537H62.9086V26.2093Z"
        fill={fill}
      />
      <path
        d="M65.7908 21.0274C65.4068 20.7035 65.2148 20.1853 65.2148 19.6024C65.2148 19.0194 65.4068 18.5012 65.7908 18.1774C66.1748 17.8535 66.6868 17.6592 67.2628 17.6592C67.8388 17.6592 68.3508 17.8535 68.7348 18.1774C69.1188 18.566 69.3108 19.0194 69.3108 19.6024C69.3108 20.1853 69.1188 20.7035 68.7348 21.0274C68.3508 21.416 67.9028 21.5455 67.2628 21.5455C66.6868 21.5455 66.1748 21.3512 65.7908 21.0274ZM65.6628 23.6183H68.8628V36.3785H65.6628V23.6183Z"
        fill={fill}
      />
      <path
        d="M71.8086 35.6665V32.7517H72.1286C73.3446 33.6586 74.6886 34.112 76.1606 34.112C77.6966 34.112 78.4006 33.7233 78.4006 32.9461C78.4006 32.3631 77.8886 31.9097 76.9286 31.5211L75.0086 30.679C73.0246 29.9017 72.0006 28.6063 72.0006 26.8574C72.0006 25.6915 72.4486 24.7847 73.2806 24.2017C74.1766 23.554 75.2646 23.2949 76.7366 23.2949C78.3366 23.2949 79.6166 23.6188 80.7046 24.2665V26.9222H80.3846C79.2326 26.2745 78.1446 25.8858 76.9926 25.8858C75.7126 25.8858 75.0726 26.2097 75.0726 26.9222C75.0726 27.4404 75.5846 27.8938 76.6086 28.3472L78.4646 29.1245C80.4486 29.9017 81.4726 31.1324 81.4726 32.8813C81.4726 34.2415 80.9606 35.2131 79.9366 35.7961C78.9126 36.379 77.6966 36.7029 76.2886 36.7029C74.6246 36.7029 73.0886 36.379 71.8086 35.6665Z"
        fill={fill}
      />
      <path
        d="M-0.390625 51.373V41.9185H2.17614V49.3049H5.99858V51.373H-0.390625ZM15.8271 46.6458C15.8271 47.6983 15.6224 48.5862 15.2131 49.3095C14.8037 50.0297 14.2513 50.5759 13.5558 50.9483C12.8602 51.3176 12.0846 51.5023 11.229 51.5023C10.3673 51.5023 9.58866 51.3161 8.89311 50.9437C8.20064 50.5682 7.64974 50.0204 7.24041 49.3002C6.83416 48.577 6.63104 47.6922 6.63104 46.6458C6.63104 45.5932 6.83416 44.7069 7.24041 43.9867C7.64974 43.2634 8.20064 42.7172 8.89311 42.3478C9.58866 41.9754 10.3673 41.7892 11.229 41.7892C12.0846 41.7892 12.8602 41.9754 13.5558 42.3478C14.2513 42.7172 14.8037 43.2634 15.2131 43.9867C15.6224 44.7069 15.8271 45.5932 15.8271 46.6458ZM13.1864 46.6458C13.1864 46.0795 13.111 45.6025 12.9602 45.2147C12.8125 44.8238 12.5924 44.5283 12.3001 44.3283C12.0108 44.1252 11.6538 44.0236 11.229 44.0236C10.8043 44.0236 10.4458 44.1252 10.1534 44.3283C9.86411 44.5283 9.64406 44.8238 9.49325 45.2147C9.34553 45.6025 9.27166 46.0795 9.27166 46.6458C9.27166 47.2121 9.34553 47.6906 9.49325 48.0815C9.64406 48.4693 9.86411 48.7647 10.1534 48.9679C10.4458 49.1679 10.8043 49.2679 11.229 49.2679C11.6538 49.2679 12.0108 49.1679 12.3001 48.9679C12.5924 48.7647 12.8125 48.4693 12.9602 48.0815C13.111 47.6906 13.1864 47.2121 13.1864 46.6458ZM16.3118 43.9867V41.9185H24.5291V43.9867H21.6854V51.373H19.1555V43.9867H16.3118Z"
        fill="#515872"
      />
    </g>
    <defs>
      <clipPath id="clip0_71_762">
        <rect
          width="96"
          height="57"
          fill="white"
          transform="translate(0 0.365234)"
        />
      </clipPath>
    </defs>
  </SvgIcon>
);
