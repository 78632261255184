import { FC, useCallback, useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import { CloseIcon } from '../../Icons/index.js';
import { Props } from './Modal.type.js';
import styles from './Modal.module.scss';
import clsx from 'clsx';

export const Modal: FC<Props> = ({
  children,
  className,
  isCloseRequest = false,
  header,
  onHide,
  withHeader = true,
}) => {
  const [isOpen, setIsOpen] = useState(true);

  const handleClose = useCallback(() => setIsOpen(false), []);

  // Если крестик для закрывания где-то в другом месте
  useEffect(() => {
    if (isCloseRequest && !withHeader) {
      handleClose();
    }
  }, [handleClose, isCloseRequest, withHeader]);

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={handleClose}
      onAfterClose={onHide}
      closeTimeoutMS={200}
      contentLabel="modal"
      className={clsx(styles.modal, className)}
      ariaHideApp={false}
    >
      <div className={styles.container}>
        {withHeader && (
          <div className={styles.header}>
            <h1>{header}</h1>
            <CloseIcon onClick={handleClose} />
          </div>
        )}
        <div className={styles.body}>{children}</div>
      </div>
    </ReactModal>
  );
};
