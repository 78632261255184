import React from 'react';
import { IconsProps } from './Icons.type.js';
import { SvgIcon } from '../../base-components/SvgIcon.js';

export const SettingsIcon: React.FC<IconsProps> = ({
  className,
  fill = '#282828',
}) => {
  return (
    <SvgIcon width={24} height={24} className={className} noFill noStroke>
      <path
        d="M3 7H6M6 7C6 8.65685 7.34315 10 9 10C10.6569 10 12 8.65685 12 7C12 5.34315 10.6569 4 9 4C7.34315 4 6 5.34315 6 7ZM3 17H9M18 17H21M18 17C18 18.6569 16.6569 20 15 20C13.3431 20 12 18.6569 12 17C12 15.3431 13.3431 14 15 14C16.6569 14 18 15.3431 18 17ZM15 7H21"
        stroke={fill}
        strokeOpacity="0.4"
        strokeLinecap="round"
      />
    </SvgIcon>
  );
};
