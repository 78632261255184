export * from './about.js';
export * from './contacts.js';
export * from './licenses.js';
export * from './mainProfits.js';
export * from './mainStats.js';
export * from './members.js';
export * from './news.js';
export * from './peoples.js';
export * from './programs.js';
export * from './tariffs.js';
export * from './metaTags.js';
export * from './breadcrumbs.js';
