import React from 'react';
import { IconsProps } from './Icons.type.js';

export const TrashIcon: React.FC<IconsProps> = (props) => {
  return (
    <svg
      width="18"
      height="18"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 172 172"
      cursor="pointer"
      {...props}
    >
      <g
        fill="none"
        fillRule="nonzero"
        stroke="none"
        strokeWidth="1"
        strokeLinecap="butt"
        strokeLinejoin="miter"
        strokeMiterlimit="10"
        strokeDashoffset="0"
      >
        <path d="M0,172v-172h172v172z" fill="none"></path>
        <g fill="#e74c3c">
          <path d="M65.84375,1.34375c-2.23062,0 -4.03125,1.80062 -4.03125,4.03125c0,2.23063 1.80063,4.03125 4.03125,4.03125h40.3125c2.23062,0 4.03125,-1.80062 4.03125,-4.03125c0,-2.23063 -1.80063,-4.03125 -4.03125,-4.03125zM32.25,20.15625c-9.63469,0 -17.46875,7.83406 -17.46875,17.46875c0,9.63469 7.83406,17.46875 17.46875,17.46875h103.46875v84.65625c0,12.59094 -10.25281,22.84375 -22.84375,22.84375h-53.75c-12.59094,0 -22.84375,-10.25281 -22.84375,-22.84375v-69.875c0,-2.23062 -1.80062,-4.03125 -4.03125,-4.03125c-2.23062,0 -4.03125,1.80063 -4.03125,4.03125v69.875c0,17.03875 13.8675,30.90625 30.90625,30.90625h53.75c17.03875,0 30.90625,-13.8675 30.90625,-30.90625v-85.13916c7.68625,-1.8275 13.4375,-8.73521 13.4375,-16.98584c0,-9.63469 -7.83406,-17.46875 -17.46875,-17.46875zM32.25,28.21875h107.5c5.18687,0 9.40625,4.21937 9.40625,9.40625c0,5.18688 -4.21938,9.40625 -9.40625,9.40625h-107.5c-5.18688,0 -9.40625,-4.21937 -9.40625,-9.40625c0,-5.18688 4.21937,-9.40625 9.40625,-9.40625zM67.1875,73.90625c-2.23062,0 -4.03125,1.80063 -4.03125,4.03125v61.8125c0,2.23063 1.80063,4.03125 4.03125,4.03125c2.23062,0 4.03125,-1.80062 4.03125,-4.03125v-61.8125c0,-2.23062 -1.80063,-4.03125 -4.03125,-4.03125zM104.8125,73.90625c-2.23062,0 -4.03125,1.80063 -4.03125,4.03125v61.8125c0,2.23063 1.80063,4.03125 4.03125,4.03125c2.23062,0 4.03125,-1.80062 4.03125,-4.03125v-61.8125c0,-2.23062 -1.80063,-4.03125 -4.03125,-4.03125z"></path>
        </g>
      </g>
    </svg>
  );
};
