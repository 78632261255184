import _Document from 'next/document.js';
import _dynamic from 'next/dynamic.js';
import _Head from 'next/head.js';
import _Image from 'next/image.js';
import _Link from 'next/link.js';
import _Router from 'next/router.js';
import _Script from 'next/script.js';

/*
 * Костыль
 * https://github.com/vercel/next.js/issues/46078
 * https://github.com/vercel/next.js/issues/46267
 * https://github.com/vercel/next.js/issues/46676
 */

export const Image = _Image as unknown as typeof _Image.default;
export const Link = _Link as unknown as typeof _Link.default;
export const Router = _Router as unknown as typeof _Router.default;
export const Script = _Script as unknown as typeof _Script.default;
export const dynamic = _dynamic as unknown as typeof _dynamic.default;
export const Head = _Head as unknown as typeof _Head.default;
export const Document = _Document as unknown as typeof _Document.default;
