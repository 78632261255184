import React, { FunctionComponent } from 'react';
import { IconsProps } from './Icons.type.js';
import { SvgIcon } from '../../base-components/SvgIcon.js';

/** Иконка с логотипом площадки светлая */
export const LogoIconLight: FunctionComponent<IconsProps> = ({
  className,
  fill = '#282828',
}) => (
  <SvgIcon width={92} height={54} className={className} noFill noStroke>
    <g clip-path="url(#clip0_84_2480)">
      <path
        d="M39.0078 4.72465V13.4996H42.0745V7.54737L88.6267 3.74284V50.0724L42.0745 46.2678V36.8178H39.0078V49.0906L91.6934 53.386V0.429199L39.0078 4.72465Z"
        fill="#F1EFF7"
      />
      <path
        d="M0.179688 17.3042H6.74237C8.6437 17.3042 10.1157 17.7337 11.2197 18.6542C12.3237 19.5747 12.8144 20.8633 12.8144 22.5815C12.8144 24.2383 12.2624 25.5269 11.2197 26.386C10.177 27.2451 8.70504 27.736 6.8037 27.736H3.49168V34.1178H0.179688V17.3042ZM3.49168 24.9747H6.6197C7.47837 24.9747 8.21437 24.7906 8.70504 24.361C9.1957 23.9315 9.44104 23.3178 9.44104 22.5201C9.44104 20.9247 8.4597 20.0656 6.55837 20.0656H3.55302V24.9747H3.49168Z"
        fill="#F1EFF7"
      />
      <path
        d="M16.7031 42.1976V40.1294H24.9205V42.1976H22.0767V49.5839H19.5469V42.1976H16.7031Z"
        fill="#168FFF"
      />
      <path
        d="M16.2195 44.8565C16.2195 45.9091 16.0148 46.797 15.6055 47.5202C15.1961 48.2404 14.6437 48.7867 13.9482 49.1591C13.2526 49.5284 12.477 49.7131 11.6214 49.7131C10.7597 49.7131 9.98106 49.5269 9.28551 49.1545C8.59304 48.779 8.04214 48.2312 7.63281 47.511C7.22656 46.7878 7.02344 45.9029 7.02344 44.8565C7.02344 43.804 7.22656 42.9176 7.63281 42.1974C8.04214 41.4742 8.59304 40.9279 9.28551 40.5586C9.98106 40.1862 10.7597 40 11.6214 40C12.477 40 13.2526 40.1862 13.9482 40.5586C14.6437 40.9279 15.1961 41.4742 15.6055 42.1974C16.0148 42.9176 16.2195 43.804 16.2195 44.8565ZM13.5788 44.8565C13.5788 44.2902 13.5034 43.8132 13.3526 43.4254C13.2049 43.0346 12.9848 42.7391 12.6925 42.5391C12.4032 42.3359 12.0462 42.2344 11.6214 42.2344C11.1967 42.2344 10.8382 42.3359 10.5458 42.5391C10.2565 42.7391 10.0365 43.0346 9.88565 43.4254C9.73793 43.8132 9.66406 44.2902 9.66406 44.8565C9.66406 45.4228 9.73793 45.9014 9.88565 46.2923C10.0365 46.68 10.2565 46.9755 10.5458 47.1786C10.8382 47.3787 11.1967 47.4787 11.6214 47.4787C12.0462 47.4787 12.4032 47.3787 12.6925 47.1786C12.9848 46.9755 13.2049 46.68 13.3526 46.2923C13.5034 45.9014 13.5788 45.4228 13.5788 44.8565Z"
        fill="#168FFF"
      />
      <path
        d="M0 49.5839V40.1294H2.56676V47.5158H6.3892V49.5839H0Z"
        fill="#168FFF"
      />
      <path
        d="M21.8978 22.0298H23.3698V24.9139H21.7138C20.7325 24.9139 19.9965 25.2821 19.5058 26.0184C19.0151 26.7548 18.7698 27.7366 18.7698 29.0866V34.1184H15.7031V22.0298H18.3405V24.6071C19.0765 22.8889 20.2418 22.0298 21.8978 22.0298Z"
        fill="#F1EFF7"
      />
      <path
        d="M30.3575 21.7231C32.0135 21.7231 33.3015 22.1527 34.2215 22.9504C35.1415 23.7481 35.6322 25.0368 35.6322 26.6936V34.1186H32.9948V32.83C32.1362 33.9345 30.9095 34.4254 29.2535 34.4254C27.9042 34.4254 26.8002 34.0572 26.0028 33.3209C25.1442 32.5845 24.7148 31.6641 24.7148 30.5595C24.7148 29.3936 25.1442 28.4731 26.0028 27.7981C26.8615 27.0618 28.0268 26.6936 29.4375 26.6936C30.7255 26.6936 31.7682 27.0004 32.5655 27.6141V26.9391C32.5655 25.2209 31.5842 24.3618 29.6828 24.3618C28.5175 24.3618 27.4135 24.6686 26.3095 25.3436H26.0028V22.5822C27.4135 21.9686 28.8242 21.7231 30.3575 21.7231ZM28.4562 31.5413C28.8855 31.8481 29.4988 31.9709 30.2962 31.9709C31.0935 31.9709 31.7068 31.8481 32.1362 31.5413C32.5655 31.2345 32.8108 30.8663 32.8108 30.3754C32.8108 29.8845 32.5655 29.5163 32.1362 29.2095C31.7068 28.9027 31.0935 28.78 30.2962 28.78C29.4988 28.78 28.8855 28.9027 28.4562 29.2095C28.0268 29.5163 27.7815 29.8845 27.7815 30.3754C27.7815 30.8663 28.0268 31.2345 28.4562 31.5413Z"
        fill="#F1EFF7"
      />
      <path
        d="M50.3556 34.1179H46.553L42.137 28.3497V34.1179H39.0703V16.0156H42.137V26.877L46.1236 21.9679H49.865L45.081 27.4293L50.3556 34.1179Z"
        fill="#F1EFF7"
      />
      <path
        d="M60.2859 24.4842H56.7899V29.516C56.7899 30.0683 56.9739 30.4978 57.2806 30.7433C57.5873 31.0501 58.0166 31.1728 58.5686 31.1728H60.1633V34.0569H58.0166C56.6673 34.0569 55.5633 33.6887 54.8273 33.0137C54.0913 32.3387 53.7233 31.3569 53.7233 30.0683V24.4228H51.0859V21.9683H53.7233V18.041H56.7899V21.9683H60.2859V24.4842Z"
        fill="#F1EFF7"
      />
      <path
        d="M63.052 19.5747C62.684 19.2679 62.5 18.777 62.5 18.2247C62.5 17.6724 62.684 17.1815 63.052 16.8747C63.42 16.5679 63.9107 16.3838 64.4627 16.3838C65.0147 16.3838 65.5053 16.5679 65.8733 16.8747C66.2413 17.2429 66.4253 17.6724 66.4253 18.2247C66.4253 18.777 66.2413 19.2679 65.8733 19.5747C65.5053 19.9429 65.076 20.0656 64.4627 20.0656C63.9107 20.0656 63.42 19.8815 63.052 19.5747ZM62.9293 22.0292H65.996V34.1179H62.9293V22.0292Z"
        fill="#F1EFF7"
      />
      <path
        d="M68.8203 33.4436V30.6822H69.127C70.2923 31.5413 71.5803 31.9709 72.991 31.9709C74.463 31.9709 75.1376 31.6027 75.1376 30.8663C75.1376 30.3141 74.647 29.8845 73.727 29.5163L71.887 28.7186C69.9856 27.9822 69.0043 26.755 69.0043 25.0981C69.0043 23.9936 69.4336 23.1345 70.231 22.5822C71.0896 21.9686 72.1323 21.7231 73.543 21.7231C75.0763 21.7231 76.303 22.03 77.3456 22.6436V25.1595H77.039C75.935 24.5459 74.8923 24.1777 73.7883 24.1777C72.5616 24.1777 71.9483 24.4845 71.9483 25.1595C71.9483 25.6504 72.439 26.08 73.4203 26.5095L75.199 27.2459C77.1003 27.9822 78.0816 29.1481 78.0816 30.805C78.0816 32.0936 77.591 33.0141 76.6096 33.5663C75.6283 34.1186 74.463 34.4254 73.1136 34.4254C71.519 34.4254 70.047 34.1186 68.8203 33.4436Z"
        fill="#F1EFF7"
      />
    </g>
    <defs>
      <clipPath id="clip0_84_2480">
        <rect width="92" height="54" fill="white" />
      </clipPath>
    </defs>
  </SvgIcon>
);
