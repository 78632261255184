import React from 'react';
import { SvgIcon } from '../../base-components/SvgIcon.js';
import { IconsProps } from './Icons.type.js';

/** Иконка с четырьмя прямоугольниками */
export const PirIcon: React.FC<IconsProps> = ({
  className,
  fill = '#ED1C24',
}) => (
  <SvgIcon width={19} height={19} className={className} noFill noStroke>
    <rect
      x="1"
      y="1"
      width="7"
      height="4"
      rx="2"
      fill={fill}
      stroke={fill}
      strokeWidth="1.5"
    />
    <rect
      x="1"
      y="8"
      width="7"
      height="10"
      rx="2.5"
      fill={fill}
      stroke={fill}
      strokeWidth="1.5"
    />
    <rect
      opacity="0.3"
      x="11"
      y="1"
      width="7"
      height="10"
      rx="2.5"
      fill={fill}
      stroke={fill}
      strokeWidth="1.5"
    />
    <rect
      x="11"
      y="14"
      width="7"
      height="4"
      rx="2"
      fill={fill}
      stroke={fill}
      strokeWidth="1.5"
    />
  </SvgIcon>
);
