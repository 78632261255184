type Tags = {
  [key: string]: {
    title: string;
    description: string;
    url: string;
    headings?: string;
    keywords?: string;
  };
};

export const metaTags: Tags = {
  main: {
    title: 'Praktis.lot - Электронная торговая площадка',
    description:
      'Официальный сайт электронной торговой площадки «Praktis.lot». Проведение онлайн торгов и аукционов, закупки по 223-ФЗ, малые закупки, коммерческие тендеры. Телефон для справок +7 (800) 555-20-83',
    url: 'https://etp.praktis.ru',
    keywords:
      'Praktis.lot, Электронная торговая площадка, Закупки, Тендеры, 223-Ф3, Предквалификация, Аккредитация, Коммерческие закупки, Закупки малого объема, ',
  },
  purchases: {
    title: 'Поиск торгов и аукционов, найти закупку по номеру',
    description:
      'Поиск торгов и аукционов на официальном сайте «Praktis.lot». Проведение онлайн торгов и аукционов, закупки по 223-ФЗ, малые закупки, коммерческие тендеры. Телефон для справок +7 (800) 555-20-83',
    url: 'https://etp.praktis.ru/purchases',
  },
  sections: {
    title: 'Услуги торговой электронной площадки - Praktis.lot',
    description:
      'Услуги по электронным торгам на официальном сайте «Praktis.lot». Проведение онлайн торгов и аукционов, закупки по 223-ФЗ, малые закупки, коммерческие тендеры. Телефон для справок +7 (800) 555-20-83',
    url: 'https://etp.praktis.ru/sections',
    headings: '',
  },
  'fz-223': {
    title: 'Закупки в соответствии с 223 Ф3 - Praktis.lot',
    description:
      'Проведение закупок по 223 Ф3 на официальном сайте «Praktis.lot». Проведение онлайн торгов и аукционов, малые закупки, коммерческие тендеры. Телефон для справок +7 (800) 555-20-83',
    url: 'https://etp.praktis.ru/purchases?section=fz-223',
    headings: 'Закупки по 223-Ф3',
  },
  prequalification: {
    title: 'Предквалификация - Praktis.lot',
    description:
      'Проведение закупок по 223 Ф3 на официальном сайте «Praktis.lot». Проведение онлайн торгов и аукционов, малые закупки, коммерческие тендеры. Телефон для справок +7 (800) 555-20-83',
    url: 'https://etp.praktis.ru/purchases?section=fz-223',
    headings: 'Предквалификация',
  },
  accreditation: {
    title: 'Аккредитация - Praktis.lot',
    description:
      'Проведение закупок по 223 Ф3 на официальном сайте «Praktis.lot». Проведение онлайн торгов и аукционов, малые закупки, коммерческие тендеры. Телефон для справок +7 (800) 555-20-83',
    url: 'https://etp.praktis.ru/purchases?section=accreditation',
    headings: 'Аккредитация',
  },
  commercial: {
    title: 'Коммерческие тендеры, закупки коммерческих организаций',
    description:
      'Проведение коммерческих тендеров на официальном сайте «Praktis.lot». Проведение онлайн торгов и аукционов, закупки по 223-ФЗ, малые закупки. Телефон для справок +7 (800) 555-20-83',
    url: 'https://etp.praktis.ru/purchases?section=commercial',
    headings: 'Коммерческие закупки',
  },
  'e-shop': {
    title: 'Электронный магазин малых закупок - Praktis.lot',
    description:
      ' Электронный магазин закупок малого объема на официальном сайте «Praktis.lot». Проведение онлайн торгов и аукционов, закупки по 223-ФЗ, коммерческие тендеры. Телефон для справок +7 (800) 555-20-83',
    url: 'https://etp.praktis.ru/purchases?section=e-shop',
    headings: 'Закупки малого объема',
  },
  'construction-materials': {
    title: 'Тендеры на строительные материалы - Praktis.lot',
    description:
      'Тендеры и закупки строительных материалов на официальном сайте «Praktis.lot». Проведение онлайн торгов и аукционов, закупки по 223-ФЗ, малые закупки, коммерческие тендеры.. Телефон для справок +7 (800) 555-20-83',
    url: 'https://etp.praktis.ru/purchases?section=construction-materials',
    headings: 'Тендеры (закупки) на поставку строительных материалов',
  },
  'construction-services': {
    title: 'Тендеры на строительно-монтажные работы',
    description:
      'Строительные тендеры на официальном сайте «Praktis.lot». Проведение онлайн торгов и аукционов, закупки по 223-ФЗ, малые закупки, коммерческие тендеры. Телефон для справок +7 (800) 555-20-83',
    url: 'https://etp.praktis.ru/purchases?section=construction-services',
    headings: 'Строительные тендеры (закупки)',
  },
  food: {
    title: 'Тендеры на продукты питания - Praktis.lot',
    description:
      'Тендеры, закупка продуктов питания для организаций на официальном сайте «Praktis.lot». Проведение онлайн торгов и аукционов, закупки по 223-ФЗ, малые закупки, коммерческие тендеры. Телефон для справок +7 (800) 555-20-83',
    url: 'https://etp.praktis.ru/purchases?section=food',
    headings: 'Тендеры на поставку продуктов питания',
  },
  logistics: {
    title: 'Тендеры на перевозку грузов и логистику - Praktis.lot',
    description:
      'Закупки, тендеры на грузоперевозки на официальном сайте «Praktis.lot». Проведение онлайн торгов и аукционов, закупки по 223-ФЗ, малые закупки, коммерческие тендеры. Телефон для справок +7 (800) 555-20-83',
    url: 'https://etp.praktis.ru/purchases?section=logistics',
    headings: 'Тендеры на оказание транспортных услуг',
  },
  medicine: {
    title: 'Медицинские тендеры, закупки в медицинские учреждения',
    description:
      'Медицинские тендеры, закупки в медицинской организации на официальном сайте «Praktis.lot». Проведение онлайн торгов и аукционов, закупки по 223-ФЗ, малые закупки, коммерческие тендеры. Телефон для справок +7 (800) 555-20-83',
    url: 'https://etp.praktis.ru/purchases?section=medicine',
    headings: 'Тендеры на поставку медицинских препаратов и оказание услуг',
  },
  members: {
    title: 'Поставщикам электронных торгов - Praktis.lot',
    description:
      'Поставщикам электронного аукциона, торгов на официальном сайте «Praktis.lot». Проведение онлайн торгов и аукционов, закупки по 223-ФЗ, малые закупки, коммерческие тендеры. Телефон для справок +7 (800) 555-20-83',
    url: 'https://etp.praktis.ru/members',
  },
  customers: {
    title: 'Заказчикам электронных торгов - Praktis.lot',
    description:
      'Заказчикам электронного аукциона, торгов на официальном сайте «Praktis.lot». Проведение онлайн торгов и аукционов, закупки по 223-ФЗ, малые закупки, коммерческие тендеры. Телефон для справок +7 (800) 555-20-83',
    url: 'https://etp.praktis.ru/customers',
  },
  tariffs: {
    title: 'Тарифы электронной торговой площадки - Praktis.lot',
    description:
      'Тарифы ЭТП на официальном сайте «Praktis.lot». Проведение онлайн торгов и аукционов, закупки по 223-ФЗ, малые закупки, коммерческие тендеры. Телефон для справок +7 (800) 555-20-83',
    url: 'https://etp.praktis.ru/tariffs',
  },
  about: {
    title: 'О площадке - Praktis.lot',
    description:
      'Информация о электронной торговой площадки «Praktis.lot». Телефон для справок +7 (800) 555-20-83',
    url: 'https://etp.praktis.ru/info/about',
  },
  news: {
    title: 'Новости площадки - Praktis.lot',
    description:
      'Новости электронной торговой площадки «Praktis.lot». Телефон для справок +7 (800) 555-20-83',
    url: 'https://etp.praktis.ru/info/news?page=1',
  },
  documents: {
    title: 'Документы - Praktis.lot',
    description:
      'Документы электронной торговой площадки «Praktis.lot». Телефон для справок +7 (800) 555-20-83',
    url: 'https://etp.praktis.ru/info/documents',
  },
  programs: {
    title: 'Программные модули - Praktis.lot',
    description:
      'Программные модули электронной торговой площадки «Praktis.lot». Телефон для справок +7 (800) 555-20-83',
    url: 'https://etp.praktis.ru/info/programs',
  },
  requisites: {
    title: 'Реквизиты - Praktis.lot',
    description:
      'Реквизиты электронной торговой площадки «Praktis.lot». Телефон для справок +7 (800) 555-20-83',
    url: 'https://etp.praktis.ru/info/requisites',
  },
  contacts: {
    title: 'Контакты - Praktis.lot',
    description:
      'Контактная информация электронной торговой площадки «Praktis.lot». Телефон для справок +7 (800) 555-20-83',
    url: 'https://etp.praktis.ru/info/contacts',
  },
  orgSection: {
    title: ' - Praktis.lot',
    description:
      ' на официальном сайте «Praktis.lot». Проведение онлайн торгов и аукционов, закупки по 223-ФЗ, малые закупки, коммерческие тендеры. Телефон для справок +7 (800) 555-20-83',
    url: 'https://etp.praktis.ru/purchases?sectionOrg=',
  },
};
