import React, { FunctionComponent } from 'react';
import { IconsProps } from './Icons.type.js';

export const EmojiIcon: FunctionComponent<IconsProps> = (props) => (
  <svg
    width="64"
    height="64"
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M40.0007 60.6667H24.0007C9.52065 60.6667 3.33398 54.48 3.33398 40V24C3.33398 9.52004 9.52065 3.33337 24.0007 3.33337H40.0007C54.4807 3.33337 60.6673 9.52004 60.6673 24V40C60.6673 54.48 54.4807 60.6667 40.0007 60.6667ZM24.0007 7.33337C11.7073 7.33337 7.33398 11.7067 7.33398 24V40C7.33398 52.2934 11.7073 56.6667 24.0007 56.6667H40.0007C52.294 56.6667 56.6673 52.2934 56.6673 40V24C56.6673 11.7067 52.294 7.33337 40.0007 7.33337H24.0007Z"
      fill="#333333"
    />
    <path
      d="M23.5205 26C21.2538 26 18.9872 25.1467 17.2538 23.4134C16.4805 22.64 16.4805 21.36 17.2538 20.5867C18.0272 19.8134 19.3072 19.8134 20.0805 20.5867C21.9738 22.48 25.0672 22.48 26.9605 20.5867C27.7338 19.8134 29.0138 19.8134 29.7872 20.5867C30.5605 21.36 30.5605 22.64 29.7872 23.4134C28.0538 25.12 25.7872 26 23.5205 26Z"
      fill="#333333"
    />
    <path
      d="M40.4795 26C38.2128 26 35.9461 25.1467 34.2128 23.4134C33.4395 22.64 33.4395 21.36 34.2128 20.5867C34.9861 19.8134 36.2661 19.8134 37.0395 20.5867C38.9328 22.48 42.0261 22.48 43.9195 20.5867C44.6928 19.8134 45.9728 19.8134 46.7461 20.5867C47.5195 21.36 47.5195 22.64 46.7461 23.4134C45.0128 25.12 42.7461 26 40.4795 26Z"
      fill="#333333"
    />
    <path
      d="M32 51.0666C24.2667 51.0666 18 44.7733 18 37.0666C18 34.64 19.9733 32.6666 22.4 32.6666H41.6C44.0267 32.6666 46 34.64 46 37.0666C46 44.7733 39.7333 51.0666 32 51.0666ZM22.4 36.6666C22.1867 36.6666 22 36.8533 22 37.0666C22 42.5866 26.48 47.0666 32 47.0666C37.52 47.0666 42 42.5866 42 37.0666C42 36.8533 41.8133 36.6666 41.6 36.6666H22.4Z"
      fill="#333333"
    />
  </svg>
);
