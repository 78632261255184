import React, { FunctionComponent } from 'react';
import { IconsProps } from './Icons.type.js';

export const StarIcon: FunctionComponent<IconsProps> = (props) => (
  <svg
    width="64"
    height="64"
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M34.6934 60.0001C33.0934 60.0001 30.6134 59.2268 28.2401 55.3335L23.5467 47.7334C23.0934 46.9868 21.7067 46.2668 20.8534 46.3201L11.9466 46.7735C6.61331 47.0401 4.74677 44.8268 4.08011 43.4935C3.41344 42.1601 2.82666 39.3068 6.29332 35.2535L11.5734 29.1202C12.1068 28.4802 12.4 27.0934 12.16 26.2934L9.46666 17.6801C8.10666 13.3601 9.62667 11.0401 10.64 10.0268C11.6533 9.01348 14 7.54678 18.32 8.98678L26.1867 11.5734C26.9067 11.8134 28.24 11.6001 28.8534 11.1735L37.0668 5.25346C40.8268 2.53346 43.5467 3.25346 44.8 3.92013C46.0533 4.58679 48.1866 6.40016 48.1066 11.0402L47.9199 21.1468C47.8933 21.8934 48.5066 23.1201 49.0933 23.5734L55.7067 28.5868C59.3067 31.3335 59.4401 34.0801 59.2001 35.4934C58.9601 36.9068 57.8934 39.4668 53.5734 40.8001L44.96 43.4935C44.16 43.7335 43.1733 44.7734 42.96 45.5734L40.9068 53.4135C39.5468 58.5601 36.8801 59.7068 35.3867 59.9201C35.2001 59.9735 34.9601 60.0001 34.6934 60.0001ZM20.9333 42.3201C23.2266 42.3201 25.76 43.7068 26.9333 45.6268L31.6267 53.2268C32.96 55.4134 34.16 56.0801 34.8 55.9734C35.4133 55.8934 36.3734 54.8801 37.0401 52.4268L39.0933 44.5868C39.6533 42.4535 41.6533 40.3468 43.76 39.7068L52.3734 37.0135C54.0267 36.5068 55.0933 35.6801 55.2533 34.8268C55.4133 33.9734 54.6666 32.8535 53.28 31.7868L46.6667 26.7735C45.04 25.5468 43.8668 23.0934 43.8934 21.0668L44.0801 10.9601C44.1068 9.17342 43.6801 7.84015 42.9068 7.44015C42.1334 7.04015 40.8267 7.4401 39.3601 8.4801L31.1467 14.4001C29.52 15.5734 26.8267 16.0001 24.8801 15.3601L17.0134 12.7735C15.36 12.2401 14.0268 12.2668 13.4134 12.8801C12.8001 13.4934 12.7466 14.8268 13.2533 16.4801L15.9466 25.0935C16.6133 27.2001 16.0266 30.0535 14.5866 31.7068L9.30667 37.8401C7.62667 39.7868 7.36002 41.1468 7.65335 41.7068C7.92002 42.2668 9.20009 42.8801 11.7334 42.7468L20.64 42.2934C20.7467 42.3201 20.8533 42.3201 20.9333 42.3201Z"
      fill="#333333"
    />
    <path
      d="M58.4268 60.6667C57.9202 60.6667 57.4136 60.48 57.0136 60.08L48.9335 52C48.1602 51.2267 48.1602 49.9467 48.9335 49.1734C49.7068 48.4001 50.9868 48.4001 51.7602 49.1734L59.8403 57.2534C60.6136 58.0267 60.6136 59.3067 59.8403 60.08C59.4403 60.48 58.9335 60.6667 58.4268 60.6667Z"
      fill="#333333"
    />
  </svg>
);
