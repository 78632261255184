import clsx from 'clsx';
import { ReactNode } from 'react';
import React from 'react';
import styles from './Row.module.scss';

interface Props {
  className?: string;
  children?: ReactNode;
  onClick?: () => void;
  style?: React.CSSProperties;
}

export const Row: React.FC<Props> = ({
  className,
  children,
  onClick,
  style,
}) => (
  <div className={clsx(styles.row, className)} onClick={onClick} style={style}>
    {children}
  </div>
);
