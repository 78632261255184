import clsx from 'clsx';
import { ReactNode } from 'react';
import React from 'react';
import styles from './Button.module.scss';

export interface Props {
  type?: 'button' | 'submit' | 'reset';
  variant?:
    | 'primary'
    | 'secondary'
    | 'green'
    | 'blue'
    | 'transparent'
    | 'stroke';
  size?: 'medium' | 'large';
  disabled?: boolean;
  onClick?: () => void;
  className?: string;
  children?: ReactNode;
}

export const Button: React.FC<Props> = ({
  type = 'button',
  variant = 'primary',
  size = 'medium',
  onClick,
  className,
  children,
  disabled,
}) => (
  <button
    type={type}
    className={clsx(styles.button, styles[variant], styles[size], className)}
    onClick={onClick}
    disabled={disabled}
  >
    {children}
  </button>
);
