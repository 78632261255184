import clsx from 'clsx';
import { Link } from '@/npm/next.js';
import React from 'react';
import styles from './Breadcrumbs.module.scss';
import { BreadcrumbsProps } from './Breadcrumbs.type.js';

export const Breadcrumbs: React.FC<BreadcrumbsProps> = ({ breadcrumbs }) => {
  if (breadcrumbs.length < 2) {
    return null;
  }

  return (
    <div className={clsx(styles.container)}>
      {breadcrumbs.map((breadcrumb, idx) =>
        breadcrumb.href ? (
          <>
            <Link
              key={`${breadcrumb.title}-${idx}`}
              href={breadcrumb.href}
              className={styles.link}
            >
              {`${breadcrumb.title}`}
            </Link>
            <span>{'>'}</span>
          </>
        ) : (
          <span key={`${breadcrumb.title}-${idx}`} className={styles.text}>
            {breadcrumb.title}
          </span>
        ),
      )}
    </div>
  );
};
