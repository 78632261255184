import React from 'react';
import { SvgIcon } from '../../base-components/SvgIcon.js';
import { IconsProps } from './Icons.type.js';

/** Иконка с красной шестерёнкой */
export const SettingsIcon2: React.FC<IconsProps> = ({
  className,
  fill = '#ED1C24',
}) => (
  <SvgIcon width={22} height={22} className={className} noFill noStroke>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.35523 0.577959C9.20212 0.36366 10.0883 0.25 11 0.25C11.9118 0.25 12.7981 0.363676 13.645 0.578005C14.3362 0.752915 14.6931 1.34983 14.8079 1.84603C14.9439 2.43381 15.3128 2.96329 15.8751 3.28798C16.3922 3.5865 16.9768 3.65663 17.5159 3.52952C18.0114 3.41269 18.7033 3.46835 19.1663 4.00871C20.0272 5.01337 20.7063 6.17937 21.1518 7.45569C21.4178 8.21767 21.0057 8.90381 20.5705 9.26236C20.0682 9.67623 19.75 10.3006 19.75 11C19.75 11.6994 20.0682 12.3238 20.5705 12.7376C21.0057 13.0962 21.4178 13.7823 21.1518 14.5443C20.7063 15.8205 20.0273 16.9864 19.1666 17.991C18.7035 18.5314 18.0116 18.587 17.516 18.4702C16.9768 18.343 16.3922 18.4131 15.8751 18.7117C15.3126 19.0364 14.9438 19.566 14.8078 20.1538C14.693 20.6501 14.3361 21.2471 13.6449 21.422C12.798 21.6363 11.9117 21.75 11 21.75C10.0884 21.75 9.20218 21.6363 8.35531 21.4221C7.66404 21.2471 7.30713 20.6501 7.19235 20.1538C7.05639 19.566 6.68751 19.0364 6.12506 18.7117C5.60795 18.4131 5.02329 18.343 4.48408 18.4702C3.98851 18.5871 3.29654 18.5314 2.83348 17.991C1.97275 16.9864 1.29376 15.8205 0.848236 14.5443C0.582222 13.7823 0.994377 13.0962 1.42955 12.7376C1.93186 12.3238 2.25005 11.6994 2.25005 11C2.25005 10.3006 1.93186 9.67622 1.42955 9.26235C0.994378 8.9038 0.582224 8.21766 0.848237 7.45567C1.29381 6.17934 1.9729 5.01334 2.83377 4.00867C3.2968 3.4683 3.98869 3.41265 4.48422 3.5295C5.02339 3.65664 5.60799 3.58651 6.12507 3.28798C6.68745 2.96329 7.05631 2.43379 7.19231 1.84601C7.30712 1.3498 7.66403 0.752863 8.35523 0.577959ZM11 14C9.34315 14 8 12.6569 8 11C8 9.34315 9.34315 8 11 8C12.6569 8 14 9.34315 14 11C14 12.6569 12.6569 14 11 14Z"
      fill={fill}
    />
  </SvgIcon>
);
