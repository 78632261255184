import React, { FunctionComponent } from 'react';
import { IconsProps } from './Icons.type.js';

export const SecurityIcon: FunctionComponent<IconsProps> = (props) => (
  <svg
    width="64"
    height="64"
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M32.0002 60.6669C31.3602 60.6669 30.7203 60.5869 30.1069 60.4003C16.2669 56.5869 6.24023 43.6535 6.24023 29.6268V17.9201C6.24023 14.9334 8.40025 11.7069 11.1736 10.5602L26.0269 4.48017C29.8936 2.90684 34.1336 2.90684 37.9736 4.48017L52.8269 10.5602C55.6002 11.7069 57.7603 14.9334 57.7603 17.9201V29.6268C57.7603 43.6268 47.7069 56.5603 33.8936 60.4003C33.2802 60.5869 32.6402 60.6669 32.0002 60.6669ZM32.0002 7.33352C30.4802 7.33352 28.9869 7.6269 27.5469 8.21357L12.6936 14.2935C11.4136 14.8268 10.2402 16.5601 10.2402 17.9468V29.6535C10.2402 41.8935 19.0403 53.2002 31.1736 56.5602C31.7069 56.7202 32.2936 56.7202 32.8269 56.5602C44.9602 53.2002 53.7603 41.8935 53.7603 29.6535V17.9468C53.7603 16.5601 52.5869 14.8268 51.3069 14.2935L36.4536 8.21357C35.0136 7.6269 33.5202 7.33352 32.0002 7.33352Z"
      fill="#333333"
    />
    <path
      d="M31.9993 35.3333C27.946 35.3333 24.666 32.0533 24.666 28C24.666 23.9466 27.946 20.6666 31.9993 20.6666C36.0527 20.6666 39.3327 23.9466 39.3327 28C39.3327 32.0533 36.0527 35.3333 31.9993 35.3333ZM31.9993 24.6666C30.1593 24.6666 28.666 26.16 28.666 28C28.666 29.84 30.1593 31.3333 31.9993 31.3333C33.8393 31.3333 35.3327 29.84 35.3327 28C35.3327 26.16 33.8393 24.6666 31.9993 24.6666Z"
      fill="#333333"
    />
    <path
      d="M32 43.3334C30.9067 43.3334 30 42.4267 30 41.3334V33.3334C30 32.24 30.9067 31.3334 32 31.3334C33.0933 31.3334 34 32.24 34 33.3334V41.3334C34 42.4267 33.0933 43.3334 32 43.3334Z"
      fill="#333333"
    />
  </svg>
);
