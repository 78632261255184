import clsx from 'clsx';
import { ReactNode } from 'react';
import React from 'react';
import styles from './Row.module.scss';

interface Props {
  className?: string;
  children?: ReactNode;
  suppressHydrationWarning?: boolean;
}

export const Col: React.FC<Props> = ({
  className,
  children,
  suppressHydrationWarning,
}) => (
  <div
    className={clsx(styles.col, className)}
    suppressHydrationWarning={suppressHydrationWarning}
  >
    {children}
  </div>
);
