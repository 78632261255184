/* eslint-disable react/jsx-props-no-spreading */
import { NextPage } from 'next';
import type { AppProps } from 'next/app.js';
import React from 'react';
import '../browser.js';
import { BreadcrumbsProvider } from '../components/_shared/Breadcrumbs/BreadcrumbsContext.js';
import { Layout } from '../components/Layout.js';
import { UserProvider } from '../components/UserContext.js';
// import { useAnalytics } from '../lib/analytics/index.js';
// import { AnalyticsSnippet } from '../lib/analytics/index.js';
import { useScrollRestoration } from '../utils/hooks/useScrollRestoration.js';
import '../styles/globals.scss';

type NextPageWithLayout = NextPage & {
  LayoutComponent?: React.ComponentType;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

export default function MyApp({
  Component,
  pageProps,
  router,
}: AppPropsWithLayout) {
  // Use the layout defined at the page level, if available
  const LayoutComponent = Component.LayoutComponent ?? Layout;

  // useAnalytics();
  useScrollRestoration(router);

  return (
    <>
      {/*<AnalyticsSnippet />*/}

      <UserProvider>
        <BreadcrumbsProvider>
          <LayoutComponent>
            <Component {...pageProps} />
          </LayoutComponent>
        </BreadcrumbsProvider>
      </UserProvider>
    </>
  );
}
